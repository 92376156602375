<template>
  <div class="battles__title">
    <SharedTitle
      v-if="props.title"
      class="battles__title_main"
      as="h1"
      :text-props="{
        gradient: { colorStops: BattlesColors.MAIN_LIGHT, toDirection: '0deg' },
        display: 'inline-flex',
        alignItems: 'center',
        size: 'inherit',
        weight: FontWeights.BOLD,
        fontCase: 'upper',
        fontFamily: 'Reaver',
        letterSpacing: '2px',
      }"
      hide-side-lines
      v-bind="props.titleProps"
      :to="ROUTING.BATTLES.MAIN.path"
    >
      <template #prepend>
        <SvgoCaseBattlesIcon class="main__icon" />
      </template>
      {{ props.title }}
    </SharedTitle>
    <SharedText
      v-if="props.showSubtitle"
      class="battles__title_secondary"
      as="h2"
      :weight="FontWeights.BOLD"
      :color="Colors.NEUTRAL.WHITE"
      v-bind="subtitleTextProps"
    >
      <slot name="subTitle">
        <span>{{ $t('battles.everyPlayerGets') }}</span>
        &nbsp;
        <NuxtImg v-if="eventStore.coinIcon" :src="eventStore.coinIcon" class="secondary__coin" />
        &nbsp;
        <span>
          <span
            v-if="props.coinsAmount"
            class="subtitle__coins"
            :style="{
              color: eventStore.coinColor,
            }"
          >
            {{ props.coinsAmount }}
          </span>
          {{ $t('battles.byParticipatingInBattles') }}
        </span>
        &nbsp;
        <SharedKitTooltip v-if="!props.hideTooltip" position="bottom">
          <TooltipDescriptionCoin />
        </SharedKitTooltip>
      </slot>
      <slot name="battleSubtitle"> </slot>
    </SharedText>
    <SharedText
      v-if="props.showBattleSubtitle"
      class="battles__title_secondary"
      as="h2"
      :weight="FontWeights.BOLD"
      :color="Colors.NEUTRAL.WHITE"
      v-bind="subtitleTextProps"
    >
      <slot name="battleSubtitle">
        <span>{{ $t('battles.titleWidget1') }}</span>
        &nbsp;
        <NuxtImg v-if="eventStore.coinIcon" :src="eventStore.coinIcon" class="secondary__coin" />
        &nbsp;
        <span>
          <span
            v-if="props.coinsAmount"
            class="subtitle__coins"
            :style="{
              color: eventStore.coinColor,
            }"
          >
            {{ props.coinsAmount }}
          </span>
          {{ $t('battles.titleWidget2') }}
        </span>
        &nbsp;
        <SharedKitTooltip v-if="!props.hideTooltip" position="top">
          <TooltipDescriptionCoin />
        </SharedKitTooltip>
      </slot>
    </SharedText>
    <SharedText
      v-if="props.showExtraSubTitle"
      class="battles__title_secondary"
      as="h3"
      :weight="FontWeights.MEDIUM"
      :color="Colors.NEUTRAL.WHITE_65"
      v-bind="subtitleTextProps"
    >
      {{ $t('battles.titleWidget3') }}
    </SharedText>
  </div>
</template>

<script setup lang="ts">
import { FontWeights } from '~/types/SharedFont.types';
import type { IPageTitleProps } from '~/features/battles/widgets/PageTitleWidget/PageTitleWidget.types';
import { BattlesColors } from '~/features/battles/constants/colors';
import { Colors } from '~/constants/colors.constants';
import { useEventStore } from '~/store/event/event.store';

const eventStore = useEventStore();

const props = withDefaults(defineProps<IPageTitleProps>(), {
  showExtraSubTitle: false,
  showSubtitle: false,
  subtitleTextProps: () => ({}),
  titleProps: () => ({}),
});

const styleVariables = computed(() => {
  const color = props.showRag
    ? {
        colorStops: [eventStore.coinColor as string, Colors.ADDITIONAL.RAFFLE_GOLD],
        toDirection: '90deg',
      }
    : eventStore.coinColor;

  return {
    eventColor: GlobalUtils.CSS.getBackgroundColor(color),
  };
});
</script>

<style scoped lang="scss">
.subtitle__coins {
  --event-color: v-bind('styleVariables.eventColor');
}
</style>

<style src="./PageTitleWidget.scss" scoped lang="scss" />
